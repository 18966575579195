import { SuccessToast, ToastError } from "../component/Toast/Notification";
import { useDeleteSponsorMutation } from "../handler/sponser.api";

export default function SponsorDeleteComponent({ id }) {
  const [deleteSponsorApi, { isLoading }] = useDeleteSponsorMutation();

  async function DeleteSponsorHandler(id) {
    try {
      const response = await deleteSponsorApi(id);
      if (response.error) {
        ToastError(response.error?.data.message);
      } else {
        SuccessToast("Successfully Delete Sponsor");
      }
    } catch (err) {
      console.log(err);
      ToastError("SERVER ERROR! TRY AGAIN");
    }
  }
  return (
    <div className="pi-delete" onClick={() => DeleteSponsorHandler(id)}>
      {isLoading ? (
        <span className="spinnerloader"></span>
      ) : (
        <i className="fa-solid fa-trash"></i>
      )}
    </div>
  );
}
