
import {SuccessToast,ToastError} from "../component/Toast/Notification"
import {useDeletePujaMutation} from '../handler/puja.api'

export default function PujaDeleteComponent({id}){

    const[deletePujaApi,{isLoading}] = useDeletePujaMutation();
    

  async function DeletePujaHandler(id){
    try{
      const response = await deletePujaApi(id);
      if(response.error){
        ToastError(response.error?.data.message);
      }else{
        SuccessToast('Successfully Delete Puja')

      }
    }catch(err){
      console.log(err);
      ToastError('SERVER ERROR! TRY AGAIN')
      
    }
  }
    return(
        <div className="pi-delete" onClick={()=>DeletePujaHandler(id)}>
        {
          isLoading?<span className="spinnerloader"></span>:<i className="fa-solid fa-trash"></i>
        }
      </div>
    )
}