import baseApi from "./base.api";

const dashboard = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    GetTotalOrders: builder.query({
      query: () => `dashboard/total/order`,
      providesTags: ["order"],
    }),

    GetTotalPuja: builder.query({
      query: () => "dashboard/total/puja",
      providesTags: ["puja"],
    }),
    GetTotalSponsor: builder.query({
      query: () => "dashboard/total/sponsor",
      providesTags: ["sponsor"],
    }),
    GetPaymentStatus: builder.query({
      query: () => "dashboard/total/payment",
      providesTags: ["order"],
    }),
    GetTotalCompleted: builder.query({
      query: () => "dashboard/total/completed",
      providesTags: ["order"],
    }),
    GetTotalInCompleted: builder.query({
      query: () => "dashboard/total/incompleted",
      providesTags: ["order"],
    }),
    GetPujaStatus: builder.query({
      query: () => "dashboard/puja/status",
      providesTags: ["order"],
    }),
  }),
});

export const {
  useGetTotalOrdersQuery,
  useGetTotalPujaQuery,
  useGetTotalSponsorQuery,
  useGetPaymentStatusQuery,
  useGetTotalCompletedQuery,
  useGetTotalInCompletedQuery,
  useGetPujaStatusQuery,
} = dashboard;
