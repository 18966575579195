import React from 'react'

import {useGetAllCountryQuery} from "../../handler/category.api"


function FilterCountry({setCountry}) {

 const getCountryApi = useGetAllCountryQuery();


  return (
    <div className='filter-drop-down-general-design'>
        <select onChange={(e)=>setCountry(e.target.value)}>
            <option value="">All Country</option>
            {
                getCountryApi.data?.data.map((item, index)=>{
                    return(
                        <option value={item} key={index}>{item}</option>
                    )
                })
            }

        </select>        


    </div>
    )
}

export default FilterCountry