import baseApi from "./base.api";

const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    Login: builder.mutation({
      query: (payload) => ({
        url: "/user/signin",
        method: "POST",
        body: payload,
      }),
    }),
    ForgetPassword: builder.mutation({
      query: (payload) => ({
        url: "/user/forgetpassword",
        method: "POST",
        body: payload,
      }),
    }),
    ResetPassword: builder.mutation({
      query: ({ password, confirm, token }) => ({
        url: `/user/resetpassword/${token}`,
        method: "PATCH",
        body: { password, confirm },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
} = authApi;
