import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";

// CSS
import "../../../css/forma.css";
import "../../../css/spinner.loader.css";
import "react-toastify/dist/ReactToastify.css";

// API
import {
  useUpdateCoordinatorMutation,
  useGetSingleCoordinatorQuery,
} from "../../../handler/coordinator.api";

// TOAST LIBRARY
import { SuccessToast, ToastError } from "../../Toast/Notification";
import { ToastContainer } from "react-toastify";

// Button component
import ButtonComponent from "../../form/ButtonComponent";

export default function EditCoordinator() {
  // Get ID from params
  const { id } = useParams();
  const navigate = useNavigate();

  // API initialization
  const [CoordinatorApi, { isLoading, isError, isSuccess, error }] =
    useUpdateCoordinatorMutation();
  const getSingleCoordinatorApi = useGetSingleCoordinatorQuery(id);

  // States
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [whatsapp, setWhatsapp] = useState("");

  // Submit coordinator data to server
  async function coordinaterSubmitHandler(event) {
    event.preventDefault();

    const formData = new FormData();
    formData.append("code", code);
    formData.append("name", name);
    formData.append("country", country);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("whatsapp", whatsapp);

    await CoordinatorApi({ formData, id });
  }

  // Show notification
  useEffect(() => {
    if (isError) {
      ToastError(error?.data?.message);
    }
    if (isSuccess) {
      SuccessToast("Successfully updated");
      setTimeout(() => {
        navigate("/coordinator");
      }, 2000);
    }
  }, [isError, isSuccess]);

  // Set initial values
  useEffect(() => {
    if (getSingleCoordinatorApi?.data?.data) {
      const data = getSingleCoordinatorApi.data.data;
      setCode(data.code || "");
      setName(data.name || "");
      setCountry(data.country || "");
      setEmail(data.email || "");
      setAddress(data.address || "");
      setWhatsapp(data.whatsapp || "");
    }
  }, [getSingleCoordinatorApi?.data?.data]);

  // ---------------------------------------------------------------

  if (getSingleCoordinatorApi.isLoading) {
    return <h1>...loading</h1>;
  } else {
    return (
      <>
        <div>
          <div className="forma-container d-flex align-items-center justify-content-center">
            <div className="forma-box">
              <div className="forma-title fs-3">Edit Coordinator Form</div>
              <form className="fs-6" onSubmit={coordinaterSubmitHandler}>
                <div className="fi-grid">
                  <div>
                    <label htmlFor="code" className="fi-name">
                      Code
                    </label>
                    <br />
                    <input
                      value={code}
                      placeholder="Enter your code"
                      type="text"
                      id="code"
                      onChange={(e) => setCode(e.target.value)}
                      name="code"
                      className="fi-input"
                    />
                  </div>
                  <div>
                    <label htmlFor="country" className="fi-name">
                      Country
                    </label>
                    <br />
                    <input
                      value={country}
                      placeholder="Enter your country"
                      type="text"
                      id="country"
                      onChange={(e) => setCountry(e.target.value)}
                      name="country"
                      className="fi-input"
                    />
                  </div>
                  <div>
                    <label htmlFor="name" className="fi-name">
                      Name
                    </label>
                    <br />
                    <input
                      value={name}
                      placeholder="Enter your name"
                      type="text"
                      id="name"
                      onChange={(e) => setName(e.target.value)}
                      name="name"
                      className="fi-input"
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="fi-name">
                      Email
                    </label>
                    <br />
                    <input
                      value={email}
                      placeholder="Enter your email"
                      type="text"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      className="fi-input"
                    />
                  </div>
                  <div>
                    <label htmlFor="address" className="fi-name">
                      Address
                    </label>
                    <br />
                    <input
                      value={address}
                      placeholder="Enter your address"
                      type="text"
                      id="address"
                      onChange={(e) => setAddress(e.target.value)}
                      name="address"
                      className="fi-input"
                    />
                  </div>
                  <div>
                    <label htmlFor="whatsapp" className="fi-name">
                      WhatsApp
                    </label>
                    <br />
                    <input
                      value={whatsapp}
                      placeholder="Enter your WhatsApp Number"
                      type="number"
                      id="whatsapp"
                      onChange={(e) => setWhatsapp(e.target.value)}
                      name="whatsapp"
                      className="fi-input"
                    />
                  </div>
                  <div></div>
                </div>
                <div className="d-flex mt-5 justify-content-between align-items-center">
                  <div className="f-btn">
                    <NavLink to="/coordinator">Back</NavLink>
                  </div>
                  <div>
                    <ButtonComponent>
                      {isLoading ? (
                        <div className="spinnerloader"></div>
                      ) : (
                        "Submit"
                      )}
                    </ButtonComponent>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* TOAST */}
        <ToastContainer />
      </>
    );
  }
}
