import React, { useEffect, useState } from "react";
// import CC from "currency-converter-lt"
import currencylist from "../../../utils/currency.json";
import { useNavigate } from "react-router-dom";

import { useAddOrderMutation } from "../../../handler/order.api";

import { SuccessToast, ToastError } from "../../Toast/Notification";
//import react-select
import Select from "react-select";

export default function PujaConfirmBox({
  item,
  setOpenDedicatedForm,
  idParams,
  setId,
  setDedicatorList,
  dedicatorList,
}) {
  //for shorting item._id for adding to ids and html for label
  const shortId = item._id;
  const sliceID = shortId.slice(-2);
  ///
  const navigate = useNavigate();
  // api
  const [orderPujaApi, { isError, isSuccess, error }] = useAddOrderMutation();
  // Set the default option based on a value in the list
  const defaultOption = currencylist.find((item) => item.code === "USD");
  //validation
  const initialValues = {
    coordinatorCode: "",
    duration: "",
    cost: "",
    startDate: "",
    endDate: "",
    paid: false,
    actualAmount: "",
    currency: defaultOption ? defaultOption.code : null,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleDurationInput = (e) => {
    setFormValues({ ...formValues, duration: e.duration, cost: e.price });
  };
  const handlepayment = (check) => {
    setFormValues({ ...formValues, paid: check });
  };
  const handleCurrency = (e) => {
    const { value } = e;

    setFormValues({ ...formValues, currency: value });
  };
  // OPEN DEDICATORLIST BOX ------------------------
  function ChooseDedicator(id) {
    setOpenDedicatedForm(true);
    setId(id);
  }
  // ------------------------------------------------

  function deleteDedicatorFromList(parentId, child_id) {
    let mutatate_dedicatorlist = dedicatorList;
    mutatate_dedicatorlist = mutatate_dedicatorlist.filter((item) => {
      // console.log(item);
      if (item._id === child_id && item.parentId === parentId) {
      } else {
        return item;
      }
    });
    setDedicatorList(mutatate_dedicatorlist);
  }

  // PUJA CONFIRM HANDLER _----------------------------------
  const ConfirmPujaHandler = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    const errors = validate(formValues);
    setFormErrors(errors);
  };
  useEffect(() => {
    // return email and name object dedicatorlist
    const Purededicatorlist = dedicatorList.map((item) => {
      return item.data[0];
    });
    const submitForm = async () => {
      // for redirecting
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        try {
          const formData = new FormData();
          formData.append("pujaId", item._id);
          formData.append("sponsorId", idParams);
          formData.append("pujaName", item.title);
          formData.append("coordinatorCode", formValues.coordinatorCode);
          formData.append("duration", formValues.duration);
          formData.append("cost", formValues.cost);
          formData.append("startDate", formValues.startDate);
          formData.append("endDate", formValues.endDate);
          formData.append("dedicators", JSON.stringify(Purededicatorlist));
          formData.append("paid", formValues.paid);
          formData.append("currency", formValues.currency);
          formData.append("actualAmount", formValues.actualAmount);
          const response = await orderPujaApi(formData);
          console.log("API Response:", response);
          navigate(`/sponsor/order/${idParams}`);;
          SuccessToast("Successfully created Coordinator");
        } catch (err) {
          console.error("API Error:", err);
          ToastError(err.message);
        }
        //   if (response.error) {
        //     ToastError(response.error.data.message);
        //   } else {
        //     navigate(`/sponsor/order/${idParams}`);
        //   }
        // } catch (err) {
        //   ToastError(err.message);
        // }
      }
    };
    submitForm();
  }, [formErrors, isSubmit]);
  const validate = (values) => {
    const errors = {};

    if (!values.duration) {
      errors.duration = "Select the Duration";
    }
    if (!values.startDate) {
      errors.startDate = "select Start Date";
    }
    if (!values.endDate) {
      errors.endDate = "Select End Date";
    }
    if (!values.actualAmount) {
      errors.actualAmount = "Input your amount";
    }
    if (!values.currency) {
      errors.currency = "Select your Currency";
    }
    return errors;
  };
  // Custom styles for React Select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      outline: "none",
      border: "none",
      marginRight: "5px", // Remove the default outline
      borderRadius: "3px 0 0 3px", // Top right and left with border-radius, bottom right and left to none
      boxShadow: state.isFocused
        ? "0 0 0 0.25rem rgba(13,110,253,.25)"
        : "none", // Add a custom box-shadow when focused
      borderColor: state.isFocused ? "blue" : provided.borderColor,
    }),
    menu: (provided) => ({
      ...provided,
      width: 100, // Custom width for the menu
    }),
  };
  // SHOW NOTIFICATION
  useEffect(() => {
    if (isError) {
      ToastError(error?.data?.message);
    }
    if (isSuccess) {
      SuccessToast("Successfully created Coordinator");
    }
  }, [isError, isSuccess]);
  // ---------------------------------------------------------------
  return (
    <div className="puja-confirm-puja-box" key={item._id}>
      <form onSubmit={ConfirmPujaHandler}>
        <div className="puja-confirm-image-and-title-container">
          <div
            className="cp-card-img"
            onClick={() => {
              console.log(item);
            }}
          >
            <img src={item.image} alt={item.title} />
          </div>

          <div>
            <h1 className="text-capitalize">{item.title}</h1>
          </div>
        </div>

        {/* ---------------------------------------- */}

        <div className="puja-confirm-coordinate-ref-container">
          <h1 className="text-capitalize">coord code</h1>
          <div>
            <input
              type="text"
              className={` ${
                formErrors.coordinatorCode ? "inputError" : "cp-d-input"
              }`}
              onChange={handleInputChange}
              placeholder="Enter Your Co.Code"
              id={`coordinateref${item.id}`}
              name="coordinatorCode"
              value={formValues.coordinatorCode}
            />
          </div>
        </div>
        {/* ------------------- */}

        <div className="puja-confirm-duration-container">
          <h1>
            Duration
            <span className="text-danger"> : {formErrors.duration}</span>
          </h1>
          <div className="puja-confirm-duration-list-container">
            {item.durationAndPrice.map((dur, index) => (
              <div
                key={dur._id}
                className={`puja-confirm-duration-list-box ${
                  formErrors.duration ? "text-danger" : ""
                }`}
              >
                <input
                  type="radio"
                  name="duration"
                  id={`duration${index + sliceID}}`}
                  onChange={() => handleDurationInput(dur)}
                  value={formValues.duration}
                  checked={dur.price === formValues.cost ? true : false}
                />
                <label htmlFor={`duration${index + sliceID}}`}>
                  {dur.duration}
                </label>
              </div>
            ))}
          </div>
        </div>
        {/* --------------------------------- */}
        {/* =----------COST------- */}
        <div className="puja-confirm-cost-container">
          <h1>Cost</h1>
          <p>USD {formValues.cost}</p>
        </div>
        {/* ----------------------------- */}

        {/* Date picker */}
        <div className="puja-confirm-date-picker-container text-capitalize">
          <h1>Period</h1>

          <div className="d-block d-sm-flex">
            <div className="d-sm-flex flex-column">
              <label htmlFor="startDate">start date</label>

              <input
                type="date"
                id="startDate"
                value={formValues.startDate}
                onChange={handleInputChange}
                name="startDate"
                className={` form-control ${
                  formErrors.startDate ? "border border-danger text-danger" : ""
                }`}
              />
            </div>
            <div className="d-sm-flex flex-column">
              <label htmlFor="endDate">finished date</label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={formValues.endDate}
                onChange={handleInputChange}
                className={` form-control  ${
                  formErrors.endDate ? "border border-danger text-danger" : ""
                }`}
              />
            </div>
          </div>
        </div>
        {/*  */}

        {/* DEDICATION SECTION------------------- */}
        <div className="puja-confirm-dedication-container">
          <h1>Dedication</h1>
          <div>
            <button onClick={() => ChooseDedicator(item._id)}>Choose</button>
            {dedicatorList?.map((dedi, index) => {
              return (
                <div key={index}>
                  {dedi.parentId === item._id && (
                    <p
                      onClick={() =>
                        deleteDedicatorFromList(dedi.parentId, dedi._id)
                      }
                    >
                      {dedi.data[0].name}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {/* -------------------------------- */}

        {/* --------------------------------------- */}
        <div className="puja-confirm-payment-container">
          <h1>Payment</h1>
          <div>
            <div className="">
              <input
                type="checkbox"
                name="paid"
                id={`paymentPaid${sliceID}`}
                className="me-1"
                checked={formValues.paid}
                onChange={() => handlepayment(true)}
              />
              <label htmlFor={`paymentPaid${sliceID}`}>Paid</label>
            </div>

            <div className="">
              <input
                type="checkbox"
                name={`paymentStatus${item.id}`}
                id={`paymentDue${sliceID}`}
                className="me-1"
                checked={!formValues.paid}
                onChange={() => handlepayment(false)}
              />
              <label htmlFor={`paymentDue${sliceID}`}>Due</label>
            </div>
          </div>
        </div>
        {/* -------------------------- */}

        {/* Actual paid------------------------------------------ */}
        <div className="acutally-paid-maincontainer">
          <h1 className="mb-2">Actual Paid</h1>
          <div>
            <div className="input-group mb-3">
              <Select
                id="coordinator"
                styles={customStyles}
                name="coordinator"
                defaultValue={defaultOption}
                value={formValues.currency}
                onChange={handleCurrency}
                options={currencylist.map((item) => ({
                  value: item.code,
                  label: item.name,
                }))}
                placeholder={
                  formValues.currency ? formValues.currency : "Currency"
                }
              />
              <input
                type="number"
                name="actualAmount"
                onChange={handleInputChange}
                value={formValues.actualAmount}
                placeholder="Enter Your Amount"
                className={`form-control ${
                  formErrors.actualAmount ? "inputError" : ""
                }`}
              />
            </div>
          </div>
        </div>

        {/* -------------------------------------------------------- */}

        <button type="submit" className="puja-confirm-button">
          Confirm
        </button>
      </form>
    </div>
  );
}
