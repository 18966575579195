import { useEffect, useState } from "react"
import "../../css/pagination.css"

const range = (start,end)=>{
    return [...Array(end-start).keys()].map((el)=>el+start);
}

function getPageCount(totalPages,pageIndex,pageCutCount){
    const ceiling = Math.ceil(pageCutCount/2);
    const floor = Math.floor(pageCutCount/2);

    if(totalPages < pageCutCount){
        return {start:1,end:totalPages+1}
    }else if(pageIndex >=1 && pageIndex <=ceiling){
        return {start:1,end:pageCutCount+1}
    }else if(pageIndex + floor >=totalPages){
        return {start:totalPages-pageCutCount+1,end:totalPages+1};
    }else{
        return {start:pageIndex - ceiling+1,end:pageIndex + floor +1 }
    }

}
export default function PaginateComponent({setPage,totalPages,pageIndex}){
    
    const pagesCut = getPageCount(totalPages,pageIndex,5);
    const pages = range(pagesCut.start,pagesCut.end);


    function prevHandler(){
        if(pageIndex > 1){
            setPage((prev)=>prev-1);
        }else{
            setPage(1);
        }
    }
    function nextHandler(){
        if(pageIndex < totalPages){
            setPage((prev)=>prev+1)
        }else{
            setPage(totalPages);
        }
    }

    return(
        <div className="pagination-main-component">
            <div>
                <button onClick={()=>setPage(1)}>1</button>
                <button onClick={prevHandler}>prev</button>
                    <div>
                        {   
                        pages.map((item, index)=>{
                            return (
                                <li style={{backgroundColor:`${pageIndex==item?'#e65a3e':''}`,color:`${pageIndex==item?'white':''}`}} onClick={()=>setPage(item)} key={index}>
                                    {item}
                                </li>
                            )
                        })
                        }
                    </div>
                <button onClick={nextHandler}>next</button>
                <button onClick={()=>setPage(totalPages)}>{totalPages}</button>

            </div>
        </div>
    
    )
}