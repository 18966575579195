import React from "react";
import Forma from "./coordinator/Forma";

export default function AddCoordinator() {
  return (
    <div>
      <Forma></Forma>
    </div>
  );
}
