import React, { useState, useEffect } from "react";
import "../../css/orderlist.css";
import PaginateComponent from "../filter/PaginateComponent";
import DebounceHook from "../hooks/DebounceHook";
import FilterCoordinatorComponent from "../filter/FilterCoordinatorComponent";
import FilterCountry from "../filter/FilterCountry";
import FilterDate from "../filter/FilterDate";
import SearchBarComponent from "../filter/SearchBarComponent";
// TOAST LIBARARY
import { SuccessToast, ToastError } from "../Toast/Notification";
import { ToastContainer } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import FilterPaidComponent from "../filter/FilterPaidComponent";
import { useGetAllOrderQuery } from "../../handler/order.api";
import FilterFinishedComponent from "../filter/FilterFinishedComponent";
import MoreDetail from "../view/ordermore/MoreDetail";
import OrderDeleteComponent from "../OrderDeleteComponent";
export default function OrderList() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const searchDebounceValue = DebounceHook(search);
  const [coordinatorCode, setCoordinatorCode] = useState("");
  const [country, setCountry] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [paid, setPaid] = useState("");
  const [finished, setFinished] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [selectedPaidStatus, setSelectedPaidStatus] = useState("unpaid");

  // Search query
  const query = `?page=${page}&limit=14&search=${searchDebounceValue}&country=${country}&coordinator=${coordinatorCode}&year=${year}&month=${month}&paid=${paid}&finished=${finished}`;
  const getAllOrderApi = useGetAllOrderQuery(query);
  const totalPages = getAllOrderApi.data?.numberOffPages;

  const handleSelectOrder = (item) => {
    setSelectedOrder(item);
    setIsDetailVisible(true);
    setSelectedPaidStatus(item.paid ? "paid" : "unpaid");
  };

  const handleClose = () => {
    setSelectedOrder(null);
    setIsDetailVisible(false);
  };
  const handleSelectChange = (event) => {
    setSelectedPaidStatus(event.target.value);
  };
  useEffect(() => {
    if (getAllOrderApi?.isError) {
      console.log(getAllOrderApi?.error);
      ToastError(getAllOrderApi?.error?.error);
      ToastError(getAllOrderApi?.error?.data?.message);
    }
  }, [getAllOrderApi?.isError]);
  return (
    <div>
      {/* Filters */}
      <div className="sponsor-filter-section">
        <SearchBarComponent setSearch={setSearch} setPage={setPage} />
        <FilterCoordinatorComponent setCoordinatorCode={setCoordinatorCode} />
        <FilterCountry setCountry={setCountry} />
        <FilterDate setYear={setYear} setMonth={setMonth} />
        <FilterPaidComponent setPaid={setPaid} />
        <FilterFinishedComponent setFinished={setFinished} />
      </div>

      {/* Order list */}
      <div className="ol-container">
        <div className="ol-head text-capitalize color">
          <div>id</div>
          <div>Puja</div>
          <div>Sponsor</div>
          <div className="ol-sm">Duration </div>
          <div>Country</div>
          <div className="ol-sm">End Date</div>
          <div className="ol-sm">Co.Code</div>
          <div style={{ textAlign: "center" }}>Action</div>
        </div>
        <div className="ol-list-con">
          {getAllOrderApi.isLoading && <div className="bigSpinner"></div>}
          {getAllOrderApi.data?.data.length === 0 ? (
            <tbody>
              <tr>
                <td colSpan="6" className="sponsordetail-no-data-found-message">
                  <h1>
                    No Data Found <span>{search}</span>
                  </h1>
                </td>
              </tr>
            </tbody>
          ) : (
            getAllOrderApi.data?.data.map((item) => {
              return (
                <div
                  key={item?.id}
                  className="ol-list ol-more"
                  style={{
                    color: `${item.paid ? "green" : "#655959"}`,
                  }}
                >
                  <div onClick={() => handleSelectOrder(item)}>{item?.id}</div>
                  <div onClick={() => handleSelectOrder(item)}>
                    {item?.pujaName}
                  </div>
                  <div onClick={() => handleSelectOrder(item)}>
                    {item?.sponsor?.name}
                  </div>
                  <div
                    onClick={() => handleSelectOrder(item)}
                    className="ol-sm"
                  >
                    {item.duration}
                  </div>
                  <div onClick={() => handleSelectOrder(item)}>
                    {item?.sponsor?.country}
                  </div>
                  <div
                    className="ol-sm"
                    onClick={() => handleSelectOrder(item)}
                  >
                    {item.endDate.slice(0, 10)}
                  </div>
                  <div
                    className="ol-sm"
                    onClick={() => handleSelectOrder(item)}
                  >
                    {item?.sponsor?.coordinator?.code
                      ? item.sponsor?.coordinator?.code
                      : `No Coord`}
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <OrderDeleteComponent id={item?._id} />
                    {/* <span
                    className="ol-payment"
                    style={{
                      backgroundColor: `${item.paid ? "green" : "tomato"}`,
                    }}
                  >
                    {item.paid ? "paid" : "unpaid"}
                  </span> */}
                  </div>
                </div>
              );
            })
          )}
        </div>

        {/* More details modal */}
        {isDetailVisible && selectedOrder && (
          <MoreDetail
            item={selectedOrder}
            isVisible={isDetailVisible}
            handleClose={handleClose}
            selectedPaidStatus={selectedPaidStatus}
            handleSelectChange={handleSelectChange}
            setSelectedPaidStatus={setSelectedPaidStatus}
          />
        )}
      </div>

      {/* Pagination */}
      <PaginateComponent
        setPage={setPage}
        totalPages={totalPages}
        pageIndex={page}
      />
      {/* TOAST CONTAINER */}
      <ToastContainer />
    </div>
  );
}
