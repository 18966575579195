import React from "react";
import "../../css/mainview.css";
import endlessKnot from "../../img/endless-knot.png";
import sponserimg from "../../img/deal.png";
import coordinator from "../../img/user.png";
import paid from "../../img/paid.png";
import unpaid from "../../img/unpaid.png";
import finished from "../../img/finished.png";
import unfinished from "../../img/unfinished.png";
//puja
import mandala from "../../img/mb.jpeg";

import {
  useGetTotalOrdersQuery,
  useGetTotalSponsorQuery,
  useGetTotalPujaQuery,
  useGetPaymentStatusQuery,
  useGetTotalInCompletedQuery,
  useGetTotalCompletedQuery,
  useGetPujaStatusQuery,
} from "../../handler/dashboard.api";
export default function MainView() {
  // api handling
  const totalPujaApi = useGetTotalPujaQuery();
  const totalOrderApi = useGetTotalOrdersQuery();
  const totalSponsorApi = useGetTotalSponsorQuery();
  const paymentApi = useGetPaymentStatusQuery();
  const completedApi = useGetTotalCompletedQuery();
  const incompletedApi = useGetTotalInCompletedQuery();
  const pujaStatusApi = useGetPujaStatusQuery();

  return (
    <div className="das-container">
      <div className="das-main">
        <div className="das-out-p">
          <div className="das-box bg-blue">
            <div className="das-icon">
              <img src={endlessKnot} className="imgicon" />
            </div>
            <div className="das-second">
              <h4 className="das-num ">
                {totalPujaApi.data?.data[0]?.count || 0}
              </h4>
              <div className="das-name">Total Puja</div>
            </div>
          </div>
        </div>
        <div className="das-out-p">
          <div className="das-box bg-purple">
            <div className="das-icon">
              <img src={sponserimg} className="imgicon" />
            </div>
            <div className="das-second">
              <h4 className="das-num ">
                {totalOrderApi.data?.data[0]?.count || 0}
              </h4>
              <div className="das-name">Total Order</div>
            </div>
          </div>
        </div>
        <div className="das-out-p">
          <div className="das-box bg-teal">
            <div className="das-icon">
              <img src={coordinator} className="imgicon" />
            </div>
            <div className="das-second">
              <h4 className="das-num ">
                {totalSponsorApi.data?.data[0]?.count || 0}
              </h4>
              <div className="das-name">Total Sponsor</div>
            </div>
          </div>
        </div>
      </div>
      <div className="das-down">
        <div>
          {/* payment section  */}
          <div className="dasd-p">
            <div className="dasdd-op">
              <div className="dasd-main">
                <div className="dasd-icon">
                  <img src={paid} alt="paid" className="dasdicon" />
                </div>
                <div className="dasd-second">
                  <h4 className="dasd-num">
                    {paymentApi.data?.data[1]?.count || 0}
                  </h4>
                  <div className="dasd-name text-capitalize">total paid</div>
                </div>
              </div>
            </div>
            <div className="dasdd-op">
              <div className="dasd-main bg-danger">
                <div className="dasd-icon">
                  <img src={unpaid} alt="unpaid" className="dasdicon" />
                </div>
                <div className="dasd-second">
                  <h4 className="dasd-num">
                    {paymentApi.data?.data[0]?.count || 0}
                  </h4>
                  <div className="dasd-name text-capitalize">total unpaid</div>
                </div>
              </div>
            </div>
          </div>

          <div className="dasd-p">
            <div className="dasdd-op">
              <div className="dasd-main">
                <div className="dasd-icon">
                  <img src={finished} alt="finished" className="dasdicon" />
                </div>
                <div className="dasd-second">
                  <h4 className="dasd-num">
                    {completedApi.data?.data[0]?.completed || 0}
                  </h4>
                  <div className="dasd-name text-capitalize">
                    total finished
                  </div>
                </div>
              </div>
            </div>
            <div className="dasdd-op">
              <div className="dasd-main bg-danger">
                <div className="dasd-icon">
                  <img src={unfinished} alt="unfinished" className="dasdicon" />
                </div>
                <div className="dasd-second">
                  <h4 className="dasd-num">
                    {incompletedApi.data?.data[0]?.incompleted || 0}
                  </h4>
                  <div className="dasd-name text-capitalize">
                    total unfinished
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* puja list  */}
        <div className="dasd-right">
          <div className="dasdr">
            <div className="dasdr-main">
              {pujaStatusApi.data?.data.map((item) => {
                return (
                  <div className="dasdr-flex" key={item._id}>
                    <div className="dasdr-flex-r">
                      <h5 className="dasdr-name">{item._id}</h5>
                      <h5 className="dasdr-number">{item.pujaCount}</h5>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
