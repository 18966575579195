import React from "react";

function FilterPaidComponent({ setPaid }) {
  return (
    <div className="filter-drop-down-general-design">
      <select onChange={(e) => setPaid(e.target.value)}>
        <option value="">All</option>
        <option value="true">PAID</option>
        <option value="false">UNPAID</option>; ;
      </select>
    </div>
  );
}

export default FilterPaidComponent;
