import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../css/spforma.css";
import { SuccessToast, ToastError } from "../../Toast/Notification";
import { ToastContainer } from "react-toastify";
import { useGetAllCoordinatorQuery } from "../../../handler/coordinator.api";
import { useAddSponsorMutation } from "../../../handler/sponser.api";
import countries from "../../../json/countries.json";
import Select from "react-select";

export default function Spforma() {
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedOption, setSelectedOption] = useState("option1");
  const [coordinatorName, setCoordinatorName] = useState("");
  const [formValues, setFormValues] = useState({
    username: "",
    surname: "",
    email: "",
    country: "",
    mailingaddress: "",
    whatsApp: "",
    wechat: "",
    others: "",
    coordinator: selectedOption === "option1" ? coordinatorName : "",
  });

  const getCoordinatorApi = useGetAllCoordinatorQuery();
  const [addCoordinatorApi] = useAddSponsorMutation();

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    setFormValues((prevValues) => ({
      ...prevValues,
      coordinator: coordinatorName,
    }));
  }, [coordinatorName]);

  const handleFormInput = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const [selectedCountry, setSelectedCountry] = useState(null);
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setFormValues({ ...formValues, country: selectedOption.label });
  };

  const [selectedCo, setSelectedCo] = useState(null);
  const handleCo = (selectedOp) => {
    setSelectedCo(selectedOp);
    setCoordinatorName(selectedOp.id);
  };

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const mappedOptions = countries.map((country) => ({
      value: country.code,
      label: country.name,
    }));
    setOptions(mappedOptions);
  }, [selectedCountry, formValues]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const errors = validate(formValues);
    setIsSubmit(true);
    setFormErrors(errors);
  };
  useEffect(() => {
    const submitForm = async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const formData = new FormData();
        if (selectedOption === "option1") {
          formData.append("coordinator", coordinatorName);
        }

        formData.append("name", formValues.username);
        formData.append("surname", formValues.surname);
        formData.append("country", formValues.country);
        formData.append("mailingAddress", formValues.mailingaddress);
        formData.append("email", formValues.email);
        formData.append("whatsApp", formValues.whatsApp);
        formData.append("weChat", formValues.wechat);
        formData.append("other", formValues.wechat);
        console.log(isSubmit);
        try {
          const res = await addCoordinatorApi(formData);
          if (res.error) {
            ToastError(res.error?.data?.message);
            console.log("hi");
          } else {
            const id = res.data.data?._id;
            navigate(`/spform/selectpuja/${id}`);
          }
        } catch (err) {
          ToastError("SERVER ERROR! TRY AGAIN");
        }
      }
    };
    submitForm();
  }, [formErrors, isSubmit]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    if (!values.email && !values.whatsApp && !values.wechat && !values.others) {
      errors.contact = "At least one contact detail must be filled";
    } else {
      if (values.email && !regex.test(values.email)) {
        errors.email = "Invalid email address";
      }
      if (
        values.whatsApp &&
        (values.whatsApp.length < 8 || values.whatsApp.length > 14)
      ) {
        errors.whatsApp = "Please enter a correct number";
      }
      if (
        values.wechat &&
        (values.wechat.length < 8 || values.wechat.length > 14)
      ) {
        errors.wechat = "Please enter a correct number";
      }

      delete errors.contact;
    }
    if (!values.coordinator && selectedOption === "option1") {
      errors.coordinator = "Select a coordinator";
    }
    if (!values.username) {
      errors.username = "Username is required";
    }
    if (!values.surname) {
      errors.surname = "Surname is required";
    }
    if (!values.mailingaddress) {
      errors.mailingaddress = "Mailing address is required";
    }
    if (!values.country) {
      errors.country = "Country is required";
    }

    return errors;
  };

  return (
    <div>
      <div className="forma-container mb-4 d-flex align-items-center justify-content-center">
        <div className="forma-box">
          <div className="forma-title fs-3">Sponsor Register</div>
          <form className="fs-6" onSubmit={handleFormSubmit}>
            <div className="fi-grid">
              <div className="d-flex">
                <div>Coordinator</div>
                <div className="form-check mx-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="option"
                    id="option1"
                    value="option1"
                    checked={selectedOption === "option1"}
                    onChange={handleRadioChange}
                  />
                  <label className="form-check-label" htmlFor="option1">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="option"
                    id="option2"
                    value="option2"
                    checked={selectedOption === "option2"}
                    onChange={handleRadioChange}
                  />
                  <label className="form-check-label" htmlFor="option2">
                    No
                  </label>
                </div>
              </div>
              <div></div>

              {selectedOption === "option1" ? (
                <div>
                  <label id="coordinator-label" className="fi-name">
                    Coordinator
                  </label>
                  <p className="errorMsg">{formErrors.coordinator}</p>
                  <Select
                    id="coordinator"
                    name="coordinator"
                    className={`${formErrors.coordinator ? "errorBorder" : ""}`}
                    value={selectedCo}
                    aria-labelledby="coordinator-label"
                    onChange={handleCo}
                    options={getCoordinatorApi.data?.data.map((item) => ({
                      value: item.code,
                      label: item.name,
                      id: item._id,
                    }))}
                    placeholder="Select Coordinator"
                  />
                </div>
              ) : (
                <div></div>
              )}
              <div></div>
              <div>
                <label htmlFor="username" className="fi-name">
                  Name
                </label>
                <input
                  placeholder="Enter your name"
                  type="text"
                  id="username"
                  name="username"
                  value={formValues.username}
                  onChange={handleFormInput}
                  autoComplete="given-name"
                  className={`fi-input ${
                    formErrors.username ? "errorBorder" : "fi-input"
                  }`}
                />
                <p className="errorMsg">{formErrors.username}</p>
              </div>
              <div>
                <label htmlFor="surname" className="fi-name">
                  Surname
                </label>
                <input
                  placeholder="Enter your Surname"
                  type="text"
                  id="surname"
                  name="surname"
                  autoComplete="family-name"
                  value={formValues.surname}
                  onChange={handleFormInput}
                  className={`fi-input ${
                    formErrors.surname ? "errorBorder" : "fi-input"
                  }`}
                />
                <p className="errorMsg">{formErrors.surname}</p>
              </div>
              <div>
                <label htmlFor="mailingaddress" className="fi-name">
                  Mailing Address
                </label>
                <input
                  placeholder="Enter your mailing address"
                  type="text"
                  id="mailingaddress"
                  name="mailingaddress"
                  value={formValues.mailingaddress}
                  onChange={handleFormInput}
                  className={`fi-input ${
                    formErrors.mailingaddress ? "errorBorder" : "fi-input"
                  }`}
                />
                <p className="errorMsg">{formErrors.mailingaddress}</p>
              </div>
              <div>
                <label id="country-label" className="fi-name">
                  Country
                </label>
                <Select
                  id="country"
                  name="country"
                  className={`${formErrors.country ? "errorBorder" : ""}`}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  options={options}
                  aria-labelledby="country-label"
                  placeholder="Select a country..."
                />
                <p className="errorMsg">{formErrors.country}</p>
              </div>
            </div>
            <div className="sp-contact fi-name">
              <div>Contact</div>
              <p className="errorMsg">{formErrors.contact}</p>
              <div className="fi-grid">
                <div>
                  <label htmlFor="email" className="fi-name">
                    Email
                  </label>
                  <input
                    placeholder="Enter your email"
                    type="text"
                    id="email"
                    name="email"
                    value={formValues.email}
                    autoComplete="email"
                    onChange={handleFormInput}
                    className={`fi-input ${
                      formErrors.email ? "errorBorder" : "fi-input"
                    }`}
                  />
                  <p className="errorMsg">{formErrors.email}</p>
                </div>
                <div>
                  <label htmlFor="whatsApp" className="fi-name">
                    WhatsApp
                  </label>
                  <input
                    placeholder="Enter your WhatsApp Number"
                    type="number"
                    id="whatsApp"
                    name="whatsApp"
                    value={formValues.whatsApp}
                    autoComplete="tel"
                    onChange={handleFormInput}
                    className={`fi-input ${
                      formErrors.whatsApp ? "errorBorder" : "fi-input"
                    }`}
                  />
                  <p className="errorMsg">{formErrors.whatsApp}</p>
                </div>
                <div>
                  <label htmlFor="wechat" className="fi-name">
                    WeChat
                  </label>
                  <input
                    placeholder="Enter your WeChat Number"
                    type="number"
                    id="wechat"
                    name="wechat"
                    autoComplete="tel"
                    value={formValues.wechat}
                    onChange={handleFormInput}
                    className={`fi-input ${
                      formErrors.wechat ? "errorBorder" : "fi-input"
                    }`}
                  />
                  <p className="errorMsg">{formErrors.wechat}</p>
                </div>
                <div>
                  <label htmlFor="others" className="fi-name">
                    Others
                  </label>
                  <input
                    placeholder="Enter here"
                    type="text"
                    id="others"
                    name="others"
                    value={formValues.others}
                    onChange={handleFormInput}
                    className={`fi-input ${
                      formErrors.others ? "errorBorder" : "fi-input"
                    }`}
                  />
                </div>
              </div>
            </div>
            <div className="sp-btn">
              <button type="submit">Next</button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
