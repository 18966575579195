import baseApi from "./base.api"


const CoordinatorApi = baseApi.injectEndpoints({

    endpoints:(builder)=>({
        AddCoordinator:builder.mutation({
            query:(payload)=>({
                url:'/coordinator',
                method:"POST",
                body:payload,
            }),
            invalidatesTags:['coordinator'],
        }),
        GetAllCoordinator:builder.query({
            query:()=>'/coordinator',
            providesTags:['coordinator'],
        }),
        GetSingleCoordinator:builder.query({
            query:(id)=>({
                url:`/coordinator/${id}`,

            })
        }),
        UpdateCoordinator:builder.mutation({
            query:post=>({
                url:`/coordinator/${post.id}`,
                method:"PATCH",
                body:post.formData
            }),
            invalidatesTags:['coordinator'],
        }),   
        
        DeleteCoordinator:builder.mutation({
            query:(id)=>({
                url:`/coordinator/${id}`,
                method:"DELETE",
            }),

            invalidatesTags:['coordinator'],
        })


        
        
        
    })
})

export const {
    useAddCoordinatorMutation,
    useGetAllCoordinatorQuery,
    useGetSingleCoordinatorQuery,
    useUpdateCoordinatorMutation,
    useDeleteCoordinatorMutation
} = CoordinatorApi;