import React from "react";
import "../../css/sponserform.css";
export default function SponersForm() {
  return (
    <div>
      <div className="sform-container align-items-center justify-content-center row">
        <div className=""></div>
      </div>
    </div>
  );
}
