import React, { useEffect, useRef, useState } from "react";
import "../../css/addpuja.css";
import "react-toastify/dist/ReactToastify.css";

import { useAddPujaMutation } from "../../handler/puja.api";
import ButtonComponent from "../form/ButtonComponent";

import { SuccessToast, ToastError } from "../Toast/Notification";
import { ToastContainer } from "react-toastify";

export default function Addpuja() {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);

  const [fields, setFields] = useState([
    { id: 1, duration: "", price: "" },
    { id: 2, duration: "", price: "" },
  ]);

  const initialValues = {
    image: "",
    title: "",
    field: fields,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  // API INITILIZATION
  const [pujaApiHandler, { isLoading }] = useAddPujaMutation();
  //  ------------------------------------------

  const inputimg = () => {
    fileInputRef.current.click();
  };
  const closeBtn = () => {
    setFile(null);
    // Clear the background image
    const mainImgElement = document.getElementById("mainImg");
    mainImgElement.style.backgroundImage = "none";
    // Revoke the object URL to free up resources
    if (file) {
      URL.revokeObjectURL(URL.createObjectURL(file));
    }
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > 2 * 1024 * 1024) {
        // File size exceeds 2MB, display an error message or take appropriate action
        alert("File size must be less than 2MB.");
        return;
      }
      setFile(selectedFile);
      setFormValues({ ...formValues, image: selectedFile.name });
      const imageUrl = URL.createObjectURL(selectedFile);
      document.getElementById(
        "mainImg"
      ).style.backgroundImage = `url(${imageUrl})`;
    }
  };

  // tirm forname length and size convertion to mb
  const truncateFileName = (fileName, maxLength) => {
    if (fileName.length > maxLength) {
      return fileName.substring(0, maxLength) + "...";
    }
    return fileName;
  };

  const convertBytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2) + " mb";
  };

  //tirm forname length and size convertion to mb
  //form validation

  //form validation
  const handlePlusClick = () => {
    const newField = { id: fields.length + 1, duration: "", price: "" };

    setFields([...fields, newField]);
    setFormValues({ ...formValues, field: [...formValues.field, newField] });
  };

  const handleRemoveClick = (id) => {
    if (fields.length > 2) {
      const updatedFields = fields.filter((field) => field.id !== id);
      setFields(updatedFields);
      setFormValues({ ...formValues, field: updatedFields });
    }
  };

  const handleFormInput = (e) => {
    const { name, value } = e.target;

    if (name === "title") {
      // If it's the 'title' field, update it directly
      setFormValues({ ...formValues, [name]: value });
    } else {
      // If it's a field in the 'fields' array, update the corresponding field
      const fieldId = parseInt(name.replace(/[^0-9]/g, ""), 10); // Extract the field id from the input name
      const updatedFields = formValues.field.map((field) =>
        field.id === fieldId
          ? { ...field, [name.replace(/\d+/g, "")]: value }
          : field
      );

      setFormValues({ ...formValues, field: updatedFields });
    }
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    setIsSubmit(true);
  };
  useEffect(() => {
    const submitForm = async () => {
      if (Object.keys(formErrors).length === 0 && file && isSubmit) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("title", formValues.title);
        formData.append("durationAndPrice", JSON.stringify(formValues.field));

        try {
          const res = await pujaApiHandler(formData);
          if (res.error) {
            ToastError(res.error?.data.message);
          } else {
            SuccessToast("Successfully uploaded");

            // Clear form fields after successful submission
            setFormValues(initialValues); // Reset all form values
            setFields([
              { id: 1, duration: "", price: "" },
              { id: 2, duration: "", price: "" },
            ]); // Reset fields to initial state
            setFile(null); // Clear selected file
            setFormErrors({}); // Clear any error messages

            // Remove the background image
            const mainImgElement = document.getElementById("mainImg");
            mainImgElement.style.backgroundImage = "none";
          }
        } catch (err) {
          ToastError("SERVER ERROR! Try again.");
        }
        setIsSubmit(false); // Reset submit state
      }
    };
    submitForm();
  }, [formErrors, isSubmit]);

  const validate = (values) => {
    const errors = {};
    if (!values.image) {
      errors.image = "select the image";
    }
    if (!values.title) {
      errors.title = "Must be filed";
    }
    for (var i = 0; i < values.field.length; i++) {
      var duration = values.field[i].duration;
      var price = values.field[i].price;
      if (!duration) {
        errors[`field[${i}].duration`] = "Duration must be filled";
      }
      if (!price) {
        errors[`field[${i}].price`] = "Price must be filled";
      }
    }
    return errors;
  };

  return (
    <div>
      <div className="forma-container d-flex align-items-center justify-content-center">
        <div className="forma-box">
          <div className="forma-title fs-4">Daily Puja management form </div>
          <form className="fs-6" onSubmit={handleFormSubmit}>
            <div className="fi-grid">
              <div>
                <div className="my-3">
                  <span className="inputimg" onClick={inputimg}>
                    Select Image
                  </span>
                </div>
                <label htmlFor="title" className="fi-name">
                  puja type
                </label>
                <input
                  placeholder="Enter your title"
                  type="text"
                  id="title"
                  name="title"
                  value={formValues.title}
                  onChange={handleFormInput}
                  className="fi-input"
                />
                <p className="errorp">{formErrors.title}</p>
              </div>
              <div>
                <div className="d-flex  flex-column align-items-center justify-content-center">
                  <div id="previmg">
                    <div
                      id="mainImg"
                      style={{
                        justifyContent: file ? "flex-end" : "center",
                      }}
                      className={`${formErrors.image ? "noImg" : ""}`}
                    >
                      {file ? (
                        <>
                          <div className="closebtn" onClick={closeBtn}>
                            <i className="fa-solid fa-xmark text-danger"></i>
                          </div>
                          <div className="name-bg">
                            <div className="img-name">
                              {truncateFileName(file.name, 20)}
                            </div>
                            <div className="img-size">
                              {convertBytesToMB(file.size)}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <i className="fa-solid fa-cloud-arrow-up fs-1"></i>
                          <p>No Image Selected</p>
                        </>
                      )}
                    </div>
                  </div>

                  <input
                    type="file"
                    id="selectimg"
                    className="fi-input"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                </div>
              </div>
              {fields.map((field, index) => (
                <React.Fragment key={field.id}>
                  <div>
                    <label htmlFor={`duration${field.id}`} className="fi-name">
                      Duration
                    </label>
                    <br />
                    <input
                      placeholder={field.duration}
                      type="text"
                      key="number"
                      id={`duration${field.id}`}
                      name={`duration${field.id}`}
                      value={formValues.field[index].duration}
                      onChange={handleFormInput}
                      className="fi-input"
                    />
                    <p className="errorp">
                      {formErrors[`field[${index}].duration`]}
                    </p>
                  </div>
                  <div>
                    <label htmlFor={`price${field.id}`} className="fi-name">
                      Price
                    </label>
                    <br />
                    <input
                      placeholder={`Enter additional price `}
                      type="number"
                      key="number"
                      id={`price${field.id}`}
                      value={formValues.field[index].price}
                      onChange={handleFormInput}
                      name={`price${field.id}`}
                      className="fi-input"
                    />
                    <p className="errorp">
                      {formErrors[`field[${index}].price`]}
                    </p>
                  </div>
                </React.Fragment>
              ))}

              <div className="my-3">
                <span
                  className="adpuja-i"
                  id="addbtn"
                  onClick={handlePlusClick}
                >
                  <i className="fa-solid fa-plus"></i> Add More
                </span>
              </div>
              {fields.length > 2 && (
                <div className="my-3">
                  <span
                    className="adpuja-i"
                    id="removebtn"
                    onClick={() =>
                      handleRemoveClick(fields[fields.length - 1].id)
                    }
                  >
                    <i className="fa-solid fa-minus"></i>
                    Remove Last
                  </span>
                </div>
              )}
            </div>
            <div className="d-flex mt-6 justify-content-between">
              <div></div>
              <div>
                <div className="f-btn">
                  <ButtonComponent>
                    {isLoading ? (
                      <div className="spinnerloader"></div>
                    ) : (
                      "Add Puja"
                    )}
                  </ButtonComponent>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer></ToastContainer>
    </div>
  );
}
