import "./App.css";

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

import Coordinator from "./component/view/Coordinator";
import View from "./component/view/View";
import SponserForm from "./component/view/SponserForm";
import Puja from "./component/view/Puja";
import Sponserdetail from "./component/view/Sponserdetail";
import Filter from "./component/view/Filter";
import Addpuja from "./component/view/Addpuja";
import Spforma from "./component/view/spform/Spforma";
import SelectPuja from "./component/view/spform/SelectPuja";
import EditCoordinator from "./component/view/coordinator/EditCoordinator";
import ConfirmPuja from "./component/view/spform/ConfirmPuja";
import AddCoordinator from "./component/view/AddCoordinator";
import OrderList from "./component/view/OrderList";

import SingleSponsorOrder from "./component/view/SingleSponsorOrder";
import Singin from "./component/view/Singin";
import RootLayout from "./layout/RootLayout";
import RequireAuth from "./component/view/RequiredAuth";
import PreventSigninRoute from "./component/view/PreventSigninRoute";

import ResetPassword from "./component/view/ResetPassword";
import NewPassword from "./component/view/NewPassword";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/user" element={<PreventSigninRoute />}>
        <Route path="signin" element={<Singin />} />
      </Route>
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/confirmpassword/:id" element={<NewPassword />} />
      <Route path="/" element={<RequireAuth />}>
        <Route path="/" element={<RootLayout />}>
          <Route path="/" element={<View />} />
          <Route path="/puja" element={<Puja />} />
          <Route path="/orderlist" element={<OrderList />} />
          <Route path="/coordinator" element={<Coordinator />} />
          <Route path="/coordinate/edit/:id" element={<EditCoordinator />} />
          <Route path="/form" element={<SponserForm />} />
          <Route path="/spform" element={<Spforma />} />
          <Route path="/spform/selectpuja/:id" element={<SelectPuja />} />
          <Route
            path="/spform/selectpuja/confirmpuja/:id"
            element={<ConfirmPuja />}
          />
          <Route path="/sponserdetail" element={<Sponserdetail />} />
          <Route path="/filter" element={<Filter />} />
          <Route path="/addpuja" element={<Addpuja />} />
          <Route path="/addcoordinator" element={<AddCoordinator />} />
          <Route path="/sponsor/order/:id" element={<SingleSponsorOrder />} />
        </Route>
      </Route>
    </>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
