import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name:'auth',
    initialState:{
        user:JSON.parse(localStorage.getItem('user')) ||null,
        token:localStorage.getItem('token')|| null,

    },
    reducers:{
        setCredentials:(state,action)=>{
            console.log(action.payload);
            localStorage.setItem('user',JSON.stringify(action.payload.user))
            localStorage.setItem('token',action.payload.token)
            state.user =JSON.parse(localStorage.getItem('user'))
            state.token =localStorage.getItem('token')

        },
        logout:(state,action)=>{
            localStorage.removeItem('user')
            localStorage.removeItem('token');
            state.user = null;
            state.token = null;
        }
    }
})

export const {setCredentials,logout} = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
