import React, { useEffect, useState } from "react";
import { useLoginMutation } from "../../handler/auth.api";
import { SuccessToast, ToastError } from "../../component/Toast/Notification";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, setCredentials } from "../../features/auth/authSlice";
//css
import "../../css/spforma.css";
import "../../css/signin.css";

import logo from "../../img/logo.png";
function Singin() {
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [authApiHandler] = useLoginMutation();

  console.log(authState, "authstate");

  //form validate
  const initialValues = {
    email: "",
    password: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const FormHandler = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };
  useEffect(() => {
    const submitForm = async () => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
        const payload = formValues;
        try {
          const res = await authApiHandler(payload);
          if (res.error) {
            return ToastError(res.error.data.message);
          }

          dispatch(
            setCredentials({ user: res.data.data, token: res.data.token })
          );
          navigate("/", { replace: true });
        } catch (err) {
          ToastError(`something got wrong: ${err}`);
        }
      }
    };
    submitForm();
  }, [formErrors, isSubmit]);
  const validate = (values) => {
    const errors = {};
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
    if (!values.email) {
      errors.email = "Email address required";
    }
    if (values.email && !regex.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.password) {
      errors.password = "Password is required";
    }

    return errors;
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handlePassword = (e) => {
    handleInputChange(e);
    setPassword(e.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      FormHandler(event);
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="d-flex align-items-center justify-content-center sign-main-container">
      <div className="signin-container">
        <div className="bg-danger d-flex  align-items-center justify-content-center p-2">
          <div className="signin-img">
            <img src={logo} alt="jklabrang" className="signlogo" />
          </div>
          <div className="sigin-title h4 mb-0 text-capitalize text-white">
            Jamgon Kongtrul Labrang
          </div>
        </div>

        <div className="signsection ">
          <div className="h3 text-center text-capitalize text-danger">
            Sign in
          </div>
          <form onSubmit={FormHandler}>
            <div className="mb-3">
              <label htmlFor="signEmail" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className={`fi-input ${
                  formErrors.email ? "errorBorder" : "fi-input"
                }`}
                id="signEmail"
                name="email"
                onChange={handleInputChange}
              />
              <p className="errorMsg">{formErrors.email}</p>
            </div>
            <div className="mb-3">
              <label htmlFor="signPassword" className="form-label">
                Password
              </label>
              <input
                className={`fi-input ${
                  formErrors.password ? "errorBorder" : "fi-input"
                }`}
                id="signPassword"
                name="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePassword}
              />
              <p className="errorMsg">{formErrors.password}</p>

              <div className="show-pass">
                <input
                  type="checkbox"
                  id="showpass"
                  checked={showPassword}
                  className="me-2"
                  onChange={togglePasswordVisibility}
                />
                <label id="showpass" onClick={togglePasswordVisibility}>
                  {showPassword ? "Hide" : "Show"} Password
                </label>
              </div>
            </div>

            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default Singin;
