import { useState, useEffect } from "react";
import { motion } from "framer-motion";

export default function AddDedicatedComponent({
  setOpenDedicatedForm,
  parent_id,
  setDedicatorList,
  setId,
  dedicatorList,
}) {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [dedicatedList, setDedicatedList] = useState(null);

  useEffect(() => {
    setDedicatedList(JSON.parse(localStorage.getItem("dedicated_list")));
  }, []);

  function checkNameError() {
    if (name.length < 3) {
      setNameError(true);
      return false;
    } else {
      setNameError(false);
      return true;
    }
  }
  // function checkEmailError() {
  //   if (email.length < 5) {
  //     setEmailError(true);
  //     console.log(emailError);
  //     return false;
  //   } else {
  //     setEmailError(false);
  //     return true;
  //   }
  // }

  function AddDedicatedHandler(id) {
    checkNameError();
    // checkEmailError();
    if (checkNameError()) {
      setEmail("");
      setName("");
      // setEmailError(false);
      setNameError(false);

      const datalist = JSON.parse(localStorage.getItem("dedicated_list"));
      console.log(datalist);
      if (datalist === null || datalist === undefined) {
        localStorage.setItem(
          "dedicated_list",
          JSON.stringify([{ id, name, email }])
        );
        const datalist = JSON.parse(localStorage.getItem("dedicated_list"));
        setDedicatedList(datalist);
      } else {
        const datalist = JSON.parse(localStorage.getItem("dedicated_list"));
        datalist.push({ id, name, email });
        localStorage.setItem("dedicated_list", JSON.stringify(datalist));
        setDedicatedList(datalist);
      }
    }
  }

  function deleteHandler(id) {
    const datalist = JSON.parse(localStorage.getItem("dedicated_list"));
    const deletedData = datalist.filter((item) => {
      return item.id !== id;
    });
    localStorage.setItem("dedicated_list", JSON.stringify(deletedData));
    setDedicatedList(deletedData);
  }

  function CheckDedicatSelect(id) {
    return dedicatorList.filter((item) => {
      return item._id == id && item.parentId == parent_id;
    });
  }

  // ADD TO DEDICATOR LIST
  function AddToDedicatList(_id, name, email) {
    if (dedicatorList.length < 1) {
      setDedicatorList([{ parentId: parent_id, _id, data: [{ name, email }] }]);
    } else {
      if (CheckDedicatSelect(_id).length > 0) {
        let mutatate_dedicatorlist = dedicatorList;
        mutatate_dedicatorlist = mutatate_dedicatorlist.filter((item) => {
          // console.log(item);
          if (item._id == _id && item.parentId == parent_id) {
          } else {
            return item;
          }
        });
        setDedicatorList(mutatate_dedicatorlist);
      } else {
        console.log("new value");
        setDedicatorList([
          ...dedicatorList,
          { parentId: parent_id, _id, data: [{ name, email }] },
        ]);
      }
    }
  }

  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      className="dedicated-list-main-wrapper"
    >
      <i
        className="fa-solid fa-square-xmark dedicated-close-button"
        onClick={() => (setOpenDedicatedForm(false), setId())}
      ></i>
      <h1>Dedicated Form</h1>

      <div className="dedicated-add-more-container">
        <div>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            autoComplete="given-name"
            value={name}
            placeholder="enter a name"
            onChange={(e) => setName(e.target.value)}
            style={{ outline: `${nameError ? "2px solid red" : ""}` }}
          />
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            autoComplete="email"
            value={email}
            placeholder="enter a email"
            onChange={(e) => setEmail(e.target.value)}
            // style={{ outline: `${emailError ? "2px solid red" : ""}` }}
          />
        </div>
        <div>
          <button
            onClick={() =>
              AddDedicatedHandler(
                `${Math.floor(Math.random() * 1000 + 1)}${Date.now()}`
              )
            }
          >
            Add
          </button>
        </div>
      </div>

      {/* list of dedication name */}
      <div className="dedicated-name-email-list-container">
        {dedicatedList?.map((item, index) => {
          const checkboxId = `checkbox${index}`;
          return (
            <div key={index}>
              {parent_id !== undefined && (
                <input
                  type="checkbox"
                  id={checkboxId}
                  onChange={() =>
                    AddToDedicatList(item.id, item.name, item.email)
                  }
                  checked={
                    CheckDedicatSelect(item.id).length > 0 ? true : false
                  }
                />
              )}
              <p>
                {parent_id !== undefined ? (
                  <label htmlFor={checkboxId}>{item.name}</label>
                ) : (
                  <span>{item.name}</span>
                )}
              </p>
              <p>
                {parent_id !== undefined ? (
                  <label htmlFor={checkboxId}>{item.email}</label>
                ) : (
                  <span>{item.email}</span>
                )}
              </p>
              <i
                className="fa-solid fa-trash"
                onClick={() => deleteHandler(item.id)}
              ></i>
            </div>
          );
        })}
      </div>
    </motion.div>
  );
}
